import { MantineProvider, MantineThemeOverride } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { useEffect } from "react";
import { atom, useRecoilState } from "recoil";
import { IonicStorage } from "../storage";
import { NotificationsProvider } from "@mantine/notifications";

export const themeData = {
  light: {
    primary: "#456BF5",
    background: "#ffffff",
    onbackground: "#000000",
    backgroundshade: "#dee4e7",
    onbackgroundshade: "#37474f",

    //
    accent: "#e3b06b",
    secondary: "#38ca3a",
    accentShade: "#80633d",
    info: "#154a61",
    success: "#1b6e4f",
    warning: "#7a5d12",
    error: "#fc5151",
  },
  dark: {
    primary: "#456BF5",
    background: "#000000",
    onbackground: "#ffffff",
    backgroundshade: "#181818",
    onbackgroundshade: "#A1A1A1",
    //
    info: "#3ABFF8",
    success: "#36D399",
    warning: "#FBBD23",
    error: "#cc0202",
    secondary: "#29912a",
    accent: "#80633d",
    accentShade: "#e3b06b",
  },
};

export const getMantineTheme = (
  themeMode: "light" | "dark"
): MantineThemeOverride => {
  return {
    primaryColor: "primary",
    colorScheme: themeMode,
    colors: {
      primary: ThemeUtils.generateShades(themeData[themeMode].primary),
      background: ThemeUtils.generateShades(themeData[themeMode].background),
      onbackground: ThemeUtils.generateShades(
        themeData[themeMode].onbackground
      ),
      backgroundshade: ThemeUtils.generateShades(
        themeData[themeMode].backgroundshade
      ),
      onbackgroundshade: ThemeUtils.generateShades(
        themeData[themeMode].onbackgroundshade
      ),
      dark: [
        "#d5d7e0",
        "#acaebf",
        "#8c8fa3",
        "#666980",
        "#4d4f66",
        "#34354a",
        "#2b2c3d",
        "#000000",
        "#000000",
        "#000000",
      ],
    },
    fontFamily: "Noto Sans",
  };
};

///
///
///

export const themeState = atom({
  key: "themeState",
  default: "light" as "light" | "dark",
});

export const useTheme = () => {
  const [themeMode, setThemeMode] = useRecoilState(themeState);

  const toggleTheme = () => {
    _setThemeMode(themeMode === "light" ? "dark" : "light");
    // _setThemeMode("light");
  };

  const _setThemeMode = async (themeMode: "light" | "dark") => {
    await IonicStorage.getGlobalBox().set("themeMode", themeMode);
    setThemeMode(themeMode);
  };

  return {
    themeMode: themeMode,
    theme: themeData[themeMode],
    setThemeMode: _setThemeMode,
    toggleTheme: toggleTheme,
  };
};

export class ThemeUtils {
  static setThemeToCSS = (themeMode: "light" | "dark") => {
    Object.keys((themeData as any)[themeMode]).forEach((key) => {
      document.body.style.setProperty(
        `--${key}`,
        (themeData as any)[themeMode][key]
      );
    });
  };

  static generateShades = (color: string) => {
    return Array.from(Array(10)).map((_, i) => {
      return color;
    }) as
      | [
          (string | undefined)?,
          (string | undefined)?,
          (string | undefined)?,
          (string | undefined)?,
          (string | undefined)?,
          (string | undefined)?,
          (string | undefined)?,
          (string | undefined)?,
          (string | undefined)?,
          (string | undefined)?
        ];
  };
}

export const ThemeProvider = (props: { children: React.ReactNode }) => {
  const { themeMode, setThemeMode } = useTheme();

  useEffect(() => {
    ThemeUtils.setThemeToCSS(themeMode);
    (async () => {
      await IonicStorage.initStorage();
      var savedMode = await IonicStorage.getGlobalBox().get("themeMode");
      // if (!savedMode) {
      //   if (
      //     window.matchMedia &&
      //     window.matchMedia("(prefers-color-scheme: dark)").matches
      //   ) {
      //     savedMode = "dark";
      //   }
      // }
      setThemeMode(savedMode ?? "light");
    })();
  }, []);

  useEffect(() => {
    ThemeUtils.setThemeToCSS(themeMode);
  }, [themeMode]);

  return (
    <MantineProvider
      theme={getMantineTheme(themeMode)}
      withGlobalStyles
      withNormalizeCSS
    >
      <ModalsProvider>
        <NotificationsProvider>{props.children}</NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  );
};
