import { Loader } from "@mantine/core";
import React from "react";
import { FiSearch } from "react-icons/fi";
import { MdClear } from "react-icons/md";
import {
  useGetPackageByID,
  useGetPackageByIDOrPhoneNumber,
} from "../../tracking/_data/package_hook";
import ItemTrecking from "./item-trecking";

function SearchItem() {
  const [hover, setHover] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const Rref = React.useRef<HTMLInputElement>(null);
  const parcel = useGetPackageByIDOrPhoneNumber();

  return (
    <div className="flex flex-col items-center w-full">
      <div
        className={`relative my-10 transition-all ease-in-out duration-300`}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        <div
          className={`absolute text-xs ${
            hover ? "-top-4" : "top-0 text-transparent"
          } flex w-full justify-center transition-all ease-in-out duration-500`}
        >
          Use tracking ID or Phone no.
        </div>
        <input
          type="text"
          placeholder="Search"
          ref={Rref}
          onChange={() => {
            setSearching(false);
          }}
          className="border rounded-full pl-3 pr-8 py-2 border-accentShade outline-none shadow-lg bg-background text-onBackground"
        />

        <MdClear
          onClick={() => {
            setSearching(false);
            Rref.current!.value = "";
          }}
          className={`absolute text-accentShade right-0 top-[7px] mr-[7px] cursor-pointer h-[21px] w-[21px] hover:h-[25px] hover:w-[25px] hover:top-[5px] hover:mr-[5px] transition-all ease-in-out duration-300 ${
            searching ? "" : "hidden"
          }`}
        />

        <FiSearch
          size={21}
          className={`absolute text-accentShade right-0 top-2 mr-2 cursor-pointer ${
            searching ? "hidden" : ""
          }`}
          onClick={() => {
            if (Rref.current!.value.length > 5) {
              setSearching(true);
              parcel.fn(Rref.current!.value);
              console.log(parcel.cpackage);
            }
          }}
        />
      </div>

      <div className={`w-full px-2 ${searching ? "" : "hidden"}`}>
        {parcel.isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loader variant="dots" color={"accentShade"} />
          </div>
        ) : parcel.error ? (
          <div className="flex justify-center items-center h-full">
            <div className="text-center text-onBackground">
              <div className="text-2xl font-bold">
                <span className="text-accentShade">Oops!</span>
              </div>
              <div className="text-lg">
                <span className="flex flex-col text-accentShade">
                  Something went wrong. Please try again later.
                </span>
                <span>{parcel.error.message}</span>
              </div>
            </div>
          </div>
        ) : parcel.cpackage ? (
          <ItemTrecking item={parcel.cpackage} />
        ) : null}
      </div>
    </div>
  );
}

export default SearchItem;
