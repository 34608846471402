import React from "react";
import { enumKey } from "../../../../utils/misc";
import { TRouteStatus } from "../_data/model/troute";

export const TRouteStatusComponent = (props: { status: TRouteStatus }) => {
  const warningColor = "#ffc107";
  const infoColor = "#17a2b8";
  const successColor = "#28a745";
  const errorColor = "#dc3545";
  const color = (() => {
    switch (props.status) {
      case TRouteStatus.NotYetDispatched:
        return warningColor;
      case TRouteStatus.OnGoing:
        return infoColor;
      case TRouteStatus.Delivered:
        return successColor;
      default:
        return infoColor;
    }
  })();

  return (
    <span
      className="text-xs px-[8px] h-fit py-[3px] text-text rounded-full w-fit"
      style={{
        backgroundColor: color,
      }}
    >
      {enumKey(TRouteStatus, props.status)}
    </span>
  );
};
