import { Divider, Text, TextInput } from "@mantine/core";
import React from "react";
import { Package } from "../_data/_models/package";

function SendSMSUi({ pkg }: { pkg: Package }) {
  const statusRef = React.useRef<HTMLInputElement>(null);
  return (
    <div>
      <Text size="sm">{`Your package ${pkg?.id} has been Dispatched from ${pkg?.source.city}.
  To track your package visit our website
  https://kingsuperfast.com/track`}</Text>
      <Divider className="my-3" />
      <TextInput
        ref={statusRef}
        disabled
        placeholder="Status"
        size="sm"
        className="w-fit absolute bottom-5 left-3 "
      />
    </div>
  );
}

export default SendSMSUi;
