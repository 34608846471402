import { Countries } from "../../utils/countries-list";

import { FcPhone } from "react-icons/fc";
import { RiWhatsappFill } from "react-icons/ri";
import { MdEmail } from "react-icons/md";
import { Button } from "@mantine/core";
import InputFieldWithLabel, {
  AutocompleteWithLabel,
} from "../../components/inputfieldwithlabel";
import { useForm } from "@mantine/form";
import { useNewShipmentEnquiry } from "./_data/user_hook";
import { useState } from "react";

function Shipment() {
  const itemCategory = [
    {
      label: "Document/Parcel",
      value: "Parcel",
    },
    {
      label: "Cargo/Freight",
      value: "Cargo",
    },
  ];
  const representing = [
    {
      label: "Individual",
      value: "Individual",
    },
    {
      label: "Business",
      value: "Business",
    },
    {
      label: "Government",
      value: "Government",
    },
  ];
  const shipingFrequency = [
    {
      label: "One Time",
      value: "One Time",
    },
    {
      label: "Regularly",
      value: "Regularly",
    },
    {
      label: "Occasionally",
      value: "Occasionally",
    },
  ];

  const form = useForm({
    initialValues: {
      fromCountry: Countries[0],
      fromState: "",
      fromCity: "",
      fromAddress: "",
      senderName: "",
      //
      toCountry: Countries[0],
      toState: "",
      toCity: "",
      toAddress: "",
      recieverName: "",
      //
      itemCategory: "",
      representing: "",
      phone: "",
      shippingFrequency: "",
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const addNewShipment = useNewShipmentEnquiry();
  const [isSubmitted, setIsSubmitted] = useState(false);

  return (
    <div className="flex flex-col max-w-4xl mx-auto items-center justify-center ">
      <div className="mt-20 font-bold text-lg sm:text-2xl xl:text-5xl">
        Book Your Shipment Order
      </div>
      <span>Shiping Services Domestic & International</span>
      <form
        onSubmit={form.onSubmit(async (values) => {
          //
          await addNewShipment.fn({
            ...values,
          });
          setIsSubmitted(true);
          //
        })}
      >
        <div className="flex flex-col justify-center items-center border border-solid rounded-lg w-full mt-20">
          {isSubmitted ? (
            <>
              <div className="p-8 ">
                Your request has been submitted. We will get back to you soon.
              </div>
            </>
          ) : (
            <>
              <div className="flex justify-evenly items-center flex-col sm:flex-row mt-4 w-full px-2 sm:space-x-2">
                <div className="flex flex-col justify-center items-center">
                  <div className="font-bold mb-4">Shipping From</div>
                  <AutocompleteWithLabel
                    title="Country"
                    options={Countries}
                    defaultValue={Countries[0]}
                    className="border border-solid rounded"
                    required
                    {...form.getInputProps("fromCountry")}
                  />
                  <InputFieldWithLabel
                    label="State"
                    required
                    {...form.getInputProps("fromState")}
                  />
                  <InputFieldWithLabel
                    label="City"
                    required
                    {...form.getInputProps("fromCity")}
                  />
                  <InputFieldWithLabel
                    label="Address From"
                    required
                    {...form.getInputProps("fromAddress")}
                  />
                  <InputFieldWithLabel
                    label="Sender By"
                    placeholder="Name"
                    required
                    {...form.getInputProps("senderName")}
                  />
                </div>
                <div className="flex flex-col justify-center items-center  my-4 max-w-sm">
                  <div className="font-bold mb-4">Shipping to</div>
                  <AutocompleteWithLabel
                    className="border border-solid rounded"
                    title="Country"
                    options={Countries}
                    required
                    {...form.getInputProps("toCountry")}
                  />
                  <InputFieldWithLabel
                    label="State"
                    required
                    {...form.getInputProps("toState")}
                  />
                  <InputFieldWithLabel
                    label="City"
                    required
                    {...form.getInputProps("toCity")}
                  />
                  <InputFieldWithLabel
                    label="Address To"
                    required
                    {...form.getInputProps("toAddress")}
                  />
                  <InputFieldWithLabel
                    label="Recived By"
                    placeholder="Name"
                    required
                    {...form.getInputProps("revieverName")}
                  />
                </div>
              </div>
              <div className=" my-3  font-bold">Other Details</div>
              <div className="flex justify-evenly items-center flex-col sm:flex-row w-full">
                <div className="flex flex-col justify-center items-center  space-y-2 max-w-sm">
                  <AutocompleteWithLabel
                    title="I am Shipping"
                    options={itemCategory}
                    required
                    {...form.getInputProps("itemCategory")}
                  />
                  <AutocompleteWithLabel
                    title="I represent"
                    options={representing}
                    required
                    {...form.getInputProps("representing")}
                  />
                </div>
                <div className="flex flex-col justify-center items-center  my-3 space-y-2 max-w-sm">
                  <InputFieldWithLabel
                    label="Phone"
                    required
                    {...form.getInputProps("phone")}
                  />
                  <AutocompleteWithLabel
                    title="I am Shiping"
                    options={shipingFrequency}
                    required
                    {...form.getInputProps("shippingFrequency")}
                  />
                </div>
              </div>
              <Button
                disabled={addNewShipment.loading}
                loading={addNewShipment.loading}
                type="submit"
                className="rounded-lg px-10 mt-3 mb-1"
              >
                Enquire Now
              </Button>
              <span>or</span>
              <span className="font-semibold  text-lg">Contact US Now!</span>
              <div className="flex justify-start items-center flex-col sm:flex-row w-full text-sm mb-20">
                <div className="flex flex-col justify-center items-center pt-4 w-1/2 ">
                  <div className="flex flex-col w-[320px] space-y-2">
                    <span className="font-medium text-base">Visit:</span>
                    <span>
                      Demo Super Fast Delivery, Near Mashroom Factory, Shubh
                      Khera, Paonta Sahib.
                    </span>
                    <span>Sirmour, HP, India </span>
                    <span>Pin: 173025</span>
                    <span>Office Timing: 09:00AM - 07:00PM</span>
                  </div>
                </div>
                <div className="flex justify-center items-start w-1/2 ">
                  <div className="flex flex-col items-start w-[320px] space-y-3">
                    <span className="font-medium  text-base">Contact via:</span>

                    <ContactButton
                      text="+91 1704357661"
                      icon={<FcPhone size={20} />}
                      href="tel:+911704357661"
                    />
                    <ContactButton
                      text="+91 8894597552"
                      icon={
                        <RiWhatsappFill size={20} className="text-secondary" />
                      }
                      href="https://wa.me/08894597552"
                    />
                    <ContactButton
                      text="kingsuperfasthomerdelivery@gmail.com"
                      icon={<MdEmail size={20} className="text-info" />}
                      href="mailto:kingsuperfasthomerdelivery@gmail.com"
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default Shipment;

export function ContactButton(props: {
  href: string;
  icon: React.ReactElement;
  text: string;
}) {
  return (
    <a
      href={props.href}
      rel="noreferrer"
      target={"_blank"}
      className="flex w-full pl-2 py-0.5 space-x-1 rounded-md items-center text-onBackgroundShade hover:bg-backgroundShade no-underline transition-colors duration-300 ease-in-out"
    >
      {props.icon}
      <span>{props.text}</span>
    </a>
  );
}
