import React, { useState } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AdminSideBar } from "./components/admin-side-bar";
import { TrackingDetails } from "../tracking/tracking-details";
import { TrackingHome } from "../tracking/tracking-home";
import { AdminAuthContainer } from "../../components/AdminAuthContainer";
import { NewTrackModal } from "../tracking/components/new-track";
import { TruckRoutes } from "./troutes/troutes";
import { TrouteNewForm } from "./troutes/components/troute_form";
import { TrouteDetailView } from "./troutes/components/troute_detail";
import { ShipmentsHome } from "./shipments/shipments-home";
import { BookingRequestHome } from "./bookingRequest/booking-request";
import Profile from "./components/profile";

export const AdminHome = () => {
  return (
    <AdminAuthContainer>
      <div className="flex h-full flex-col sm:flex-row">
        {/* Sidebar */}

        <AdminSideBar onAddNewTracking={() => {}} />

        <Routes>
          <Route path="" element={<Navigate to="truckRoutes" />} />
          <Route path="profile" element={<Profile />} />
          <Route
            path="tracking/*"
            element={
              <>
                <TrackingHome />
                <Outlet />
              </>
            }
          >
            <Route path="new" element={<NewTrackModal />} />
            <Route path=":id" element={<TrackingDetails />} />
          </Route>
          <Route
            path="truckRoutes/*"
            element={
              <>
                <TruckRoutes />
                <Outlet />
              </>
            }
          >
            <Route path="new" element={<TrouteNewForm />} />
            <Route path=":id" element={<TrouteDetailView />} />
          </Route>
          <Route
            path="shipments/*"
            element={
              <>
                <ShipmentsHome />
                <Outlet />
              </>
            }
          >
            {/* <Route path="new" element={<TrouteNewForm />} />
            <Route path=":id" element={<TrouteDetailView />} /> */}
          </Route>
          <Route
            path="bookingRequests/*"
            element={
              <>
                <BookingRequestHome />
                <Outlet />
              </>
            }
          >
            {/* <Route path="new" element={<TrouteNewForm />} />
            <Route path=":id" element={<TrouteDetailView />} /> */}
          </Route>
        </Routes>

        {/*  */}
      </div>
    </AdminAuthContainer>
  );
};
