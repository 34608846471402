import React from "react";
import { Route, Routes } from "react-router-dom";
import { AdminHome } from "./screens/admin/admin-home";
import UserHome from "./screens/user/user-home";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="*" element={<UserHome />} />
      <Route path="/admin/*" element={<AdminHome />} />
    </Routes>
  );
};
