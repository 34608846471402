import { Navigate, Route, Routes } from "react-router-dom";
import Footer from "../../components/footer";
import MainHeader from "../../components/Header";
import VehiclePage from "./logistics";
import Shipment from "./shipment";
import Test from "../../components/test";
import Track from "./track";
import LoginPage from "./login";

function UserHome() {
  return (
    <div className="flex flex-col h-full w-full bg-background text-onBackground ">
      <MainHeader />
      <div className="h-full overflow-auto flex flex-col justify-between">
        <Routes>
          <Route path="" element={<Navigate to={"track"} />} />
          <Route path="track" element={<Track />} />
          <Route path="shipment" element={<Shipment />} />
          <Route path="logistics" element={<VehiclePage />} />
          <Route path="login" element={<LoginPage />} />
          {/* <Route path="login" element={<Test />} /> */}
        </Routes>

        <Footer />
      </div>
    </div>
  );
}

export default UserHome;
