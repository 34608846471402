/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import MenuButtons, { ButtonProps } from "./menu-button";
import { Button } from "@mantine/core";
import { TbHome2 } from "react-icons/tb";
import { FaShippingFast } from "react-icons/fa";
import { GoPackage } from "react-icons/go";
import { IoIosLogIn } from "react-icons/io";
import Logo from "./logo";
import { Navigate, useNavigate } from "react-router-dom";
import { RiMenu5Fill } from "react-icons/ri";
import { MdClose } from "react-icons/md";
import { ThemeSwither } from "./ThemeSwither";

const buttonClass =
  "bg-accent active:bg-accentShade text-onBackground w-full outline-none hover:bg-accent ";

export default function MainHeader() {
  const buttonList: ButtonProps[] = [
    { title: "Track Your Package", path: "/track" },
    { title: "Shipment", path: "/shipment" },
    { title: "Booking For Logistics", path: "/logistics" },
    { title: "Login", path: "/login" },
  ];
  const navigate = useNavigate();
  return (
    <Popover className="relative w-full bg-accent z-50">
      <div className="max-w-full mx-auto px-4 sm:px-6 ">
        <div className="flex justify-between items-center py-4  lg:space-x-10">
          <Logo />
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-accentShade h-12 w-12 text-background border-transparent rounded-md p-2 inline-flex items-center justify-center  outline-none">
              <RiMenu5Fill className="h-7 w-7" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10 ">
            <MenuButtons list={buttonList} />
            {/* this part is pasted below */}
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg bg-backgroundShade">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Logo />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-accentShade rounded-md p-2 inline-flex items-center justify-center text-background outline-none border-transparent">
                    <MdClose
                      className="h-6 w-6"
                      aria-hidden="true"
                      color="background"
                    />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6 space-y-2">
                <Button
                  leftIcon={<TbHome2 size={25} />}
                  className={buttonClass}
                  onClick={() => {
                    navigate("/track");
                  }}
                >
                  <span className="w-[150px]">Track</span>
                </Button>
                <Button
                  leftIcon={<GoPackage size={25} />}
                  className={buttonClass}
                  onClick={() => {
                    navigate("/shipment");
                  }}
                >
                  <span className="w-[150px]">Shipment</span>
                </Button>
                <Button
                  leftIcon={<FaShippingFast size={25} />}
                  className={buttonClass}
                  onClick={() => {
                    navigate("/logistics");
                  }}
                >
                  <span className="w-[150px]">Booking For Logistics</span>
                </Button>
                <Button
                  leftIcon={<IoIosLogIn size={25} />}
                  className={buttonClass}
                  onClick={() => {
                    navigate("/admin");
                  }}
                >
                  <span className="w-[150px]">Login</span>
                </Button>
                <ThemeSwither></ThemeSwither>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
