import React, { useEffect } from "react";

const useFormatDate = (
  date?: Date,
  formatOptions?: Intl.DateTimeFormatOptions | undefined
) => {
  const [data, setData] = React.useState("");

  useEffect(() => {
    const d = Intl.DateTimeFormat(
      "en-IN",
      formatOptions ?? {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    ).format(date);
    setData(d);
  }, [date, formatOptions]);

  return [data];
};
const useFormatTime = (
  time?: Date,
  formatOptions?: Intl.DateTimeFormatOptions | undefined
) => {
  const [data, setData] = React.useState("");

  useEffect(() => {
    const d = Intl.DateTimeFormat(
      "en-IN",
      formatOptions ?? {
        hour: "2-digit",
        minute: "2-digit",
      }
    ).format(time);
    setData(d);
  }, [time, formatOptions]);

  return [data];
};
export default useFormatDate;
export { useFormatTime };
