import { AndroidPermissions } from "@awesome-cordova-plugins/android-permissions";
import { SMS } from "@awesome-cordova-plugins/sms";
import React from "react";
import { Notification } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { title } from "process";
import { useHref } from "react-router-dom";

function useSendMessage() {
  const [loading, setLoading] = React.useState(false);

  function sendSMS(phone?: string, message?: string) {
    setLoading(true);
    AndroidPermissions.checkPermission(
      AndroidPermissions.PERMISSION.SEND_SMS
    ).then(
      (result) => {},
      (err) =>
        AndroidPermissions.requestPermissions([
          AndroidPermissions.PERMISSION.SEND_SMS,
          AndroidPermissions.PERMISSION.READ_PHONE_STATE,
        ])
    );
    if (phone) {
      SMS.send(phone!, message!, {
        android: {
          intent: "",
        },
      })
        .then((result) =>
          showNotification({
            title: "SMS Sent",
            message: "SMS Sent Successfully to " + phone,
          })
        )
        .catch((err) =>
          showNotification({
            title: "SMS Failed",
            message: "SMS Failed to send to " + phone,
          })
        );
      SMS.hasPermission().then(() => {});
    } else {
      showNotification({
        title: "Phone Number Missing",
        message: "Please add a valid phone number",
      });
    }

    setLoading(false);
  }
  function sendWhatsApp(phone?: string, message?: string) {
    window.open(
      //   `https://api.whatsapp.com/send?phone=" + ${phone} + "&text=" + ${message}`,
      `https://wa.me/${phone}?text=${message}`,
      "_blank"
    );
  }

  return {
    loading: loading,
    sms: sendSMS,
    whatsApp: sendWhatsApp,
  };
}

export default useSendMessage;
