import { Storage } from "@ionic/storage";

export namespace IonicStorage {
  let _globalBox: Storage;
  export const getGlobalBox = () => _globalBox;

  export async function initStorage(): Promise<void> {
    if (!_globalBox) {
      _globalBox = new Storage();
      await _globalBox.create();
    }
  }
}
