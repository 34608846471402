import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { useState } from "react";
import { BookingRequest } from "./booking_req_model";
import { Shipment } from "./shipment_model";

//
export function useNewShipmentEnquiry() {
  const [loading, setLoading] = useState(false);

  const fn = async (ship: Shipment) => {
    setLoading(true);
    //
    const fs = getFirestore();
    const c = collection(fs, "shipments");
    const id = await addDoc(c, {
      ...ship,
      createdAt: new Date(),
    });
    //
    setLoading(false);
    return id;
  };
  return {
    loading,
    fn,
  };
}
//
export function useNewBookingRequestEnquiry() {
  const [loading, setLoading] = useState(false);

  const fn = async (bookingReq: BookingRequest) => {
    setLoading(true);
    //
    const fs = getFirestore();
    const c = collection(fs, "bookingRequest");
    const id = await addDoc(c, {
      ...bookingReq,
      createdAt: new Date(),
    });
    //
    setLoading(false);
    return id;
  };
  return {
    loading,
    fn,
  };
}

//
export function useGetAllShipment() {
  const q = useQuery(["shipments"], async () => {
    const fs = getFirestore();
    const c = collection(fs, "shipments");
    const docs = await getDocs(c);
    return docs.docs.map((d) => ({ ...d.data(), id: d.id } as Shipment));
  });
  return {
    loading: q.isLoading,
    data: q.data,
    error: q.error,
  };
}

//
export function useGetAllBookingRequests() {
  const q = useQuery(["bookingRequest"], async () => {
    const fs = getFirestore();
    const c = collection(fs, "bookingRequest");
    const docs = await getDocs(c);
    return docs.docs.map((d) => ({ ...d.data(), id: d.id } as BookingRequest));
  });
  return {
    loading: q.isLoading,
    data: q.data,
    error: q.error,
  };
}

//

export function useDeleteShipment() {
  const [loading, setLoading] = useState(false);
  const qc = useQueryClient();

  const fn = async (id: string) => {
    setLoading(true);
    //
    const fs = getFirestore();
    const c = collection(fs, "shipments");
    await deleteDoc(doc(c, id));
    qc.invalidateQueries(["shipments"]);
    //
    setLoading(false);
  };
  return {
    loading,
    fn,
  };
}

//

export function useDeleteBookingRequest() {
  const [loading, setLoading] = useState(false);
  const qc = useQueryClient();

  const fn = async (id: string) => {
    setLoading(true);
    //
    const fs = getFirestore();
    const c = collection(fs, "bookingRequest");
    await deleteDoc(doc(c, id));
    qc.invalidateQueries(["bookingRequest"]);
    //
    setLoading(false);
  };
  return {
    loading,
    fn,
  };
}
