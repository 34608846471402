import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
  createNewPackage,
  fetchAllPackages,
  fetchAllPackagesByRouteID,
  fetchPackageByID,
  fetchPackageByPhoneNumber,
} from "./package_api";
import { Package } from "./_models/package";

export function useAllPackages(limitedBy?: number) {
  const q = useQuery(
    ["allPackages"],
    () => fetchAllPackages(limitedBy ?? 50),
    {}
  );

  return {
    data: q.data,
    isLoading: q.isLoading,
    error: q.error,
  };
}

export function useAddNewPackage() {
  const [loading, setLoading] = useState(false);
  const qc = useQueryClient();
  const fn = async (cPackage: Package) => {
    setLoading(true);

    const id = await createNewPackage(cPackage);
    qc.invalidateQueries(["allPackages"]);
    setLoading(false);
    return id;
  };
  return {
    loading,
    fn,
  };
}

export function useGetPackageByID(id?: string) {
  const q = useQuery(["package", id], () => fetchPackageByID(id!), {
    enabled: !!id,
  });
  return {
    data: q.data,
    isLoading: q.isLoading,
    error: q.error,
  };
}

export function useGetPackageByIDOrPhoneNumber() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [cpackage, setCpackage] = useState<Package | undefined>();
  const fn = async (id: string) => {
    setLoading(true);
    setError(null);
    setCpackage(undefined);

    try {
      //
      let p: Package | undefined = undefined;
      if (id.startsWith("PD")) {
        // is a package id
        let _id = id.replace("-", "");
        _id = _id.substring(2);
        _id = "PD-" + _id;

        p = await fetchPackageByID(_id);
      } else {
        // is a phone number
        let _ph = id;
        // check if it starts with +91
        if (_ph.startsWith("+91") && _ph.length > 10) {
          _ph = _ph.replace("+91", "");
        }
        if (_ph.startsWith("91") && _ph.length > 10) {
          _ph = _ph.substring(2);
        }

        p = await fetchPackageByPhoneNumber(_ph);
      }
      //
      setCpackage(p);
    } catch (e) {
      setError(e as any);
    }
    setLoading(false);
  };
  return {
    isLoading: loading,
    error,
    fn,
    cpackage,
  };
}

export function useGetPackagesByRouteID(routeID?: string) {
  const q = useQuery(
    ["routePackages", routeID],
    () => fetchAllPackagesByRouteID(routeID!),
    {
      enabled: !!routeID,
    }
  );
  return {
    data: q.data,
    isLoading: q.isLoading,
    error: q.error,
  };
}
