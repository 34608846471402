export interface TRoute {
  id?: string;
  //
  routeID: string;
  name?: string;
  description?: string;
  //
  driver: { Key: string; Value: string }[];
  //
  source: Location;
  destination: Location;
  //
  route: Location[];
  locations?: Location[];
  status: TRouteStatus;
  locationStatus: LocationStatus;
  //
  capacity: number;
  //
  packages: string[];
}

export enum TRouteStatus {
  NotYetDispatched = "not_yet_dispatched",
  OnGoing = "on_going",
  Delivered = "delivered",
}

export enum LocationStatus {
  NotYetDispatched = "not_yet_dispatched",
  Dispatched = "dispatched",
  Reached = "reached",
  Delivered = "delivered",
}

export interface Location {
  lat?: number;
  lng?: number;
  city: string;
  address?: string;
  reachedAt?: Date;
}
