import { Modal } from "@mantine/core";
import { useState } from "react";
import { Location } from "../../admin/troutes/_data/model/troute";
import { Package } from "../../tracking/_data/_models/package";
import useFormatDate, { useFormatTime } from "./useFormatDate";

function ItemTrecking({ item }: { item: Package }) {
  console.log("this is item", item);

  const [opened, setOpened] = useState(false);
  const src =
    "https://static.vecteezy.com/system/resources/previews/000/350/131/non_2x/vector-package-icon.jpg";
  return (
    <>
      <div>
        <div className="flex flex-col items-center w-full border border-solid rounded-xl ">
          <span className="text-xl font-semibold text-center border-0 border-solid border-b w-full py-2">
            Package Details
          </span>
          <div className="flex flex-col md:flex-row w-full justify-evenly items-center p-2">
            <div className="flex flex-col w-full max-w-sm ">
              <ShowItem label="Service Type" value={item.serviceType} />
              <ShowItem label="Dispatch From" value={item.source.city} />
              <ShowItem label="Dispatch to" value={item.destination.city} />
              <ShowItem label="Package Type" value={item.packageType} />
              <ShowItem label="Package Weight" value={item.packageWeight} />
              <ShowItem label="Package Status" value={item.status} />
            </div>
            <div className="flex flex-col w-full max-w-sm  overflow-hidden">
              <img
                src={item.image === "" && item.image ? item.image : src}
                alt="package"
                onClick={() => setOpened(true)}
                className="w-full object-contain h-full md:h-[150px]"
              />
              <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                className="p-0"
                overlayOpacity={0.55}
                overlayBlur={3}
                overflow="outside"
                centered
                closeButtonLabel="Close"
                padding={0}
                styles={{
                  header: { padding: "0px", margin: "0px" },
                  close: {
                    display: "none",
                    padding: "0px",
                    margin: "0px",
                  },
                }}
              >
                <img
                  src={item.image === "" && item.image ? item.image : src}
                  alt="package"
                  className="w-full h-full p-2"
                  onClick={() => setOpened(true)}
                />
              </Modal>
            </div>
          </div>
        </div>
        <div className="w-full border border-solid mt-10 rounded-xl ">
          <ShowUpdate />
          {item.locations.map((update, i) => (
            <ShowUpdate update={update} index={i} />
          ))}
        </div>
      </div>
    </>
  );
}
export function ShowItem(props: { label: string; value: string }) {
  return (
    <div className="flex  w-full max-w-sm ">
      <div className="w-[180px]">{props.label}</div>
      <div className="w-full ">{": " + props.value}</div>
    </div>
  );
}
export function ShowUpdate({
  update,
  index,
}: {
  update?: Location;
  index?: number;
}) {
  const date = useFormatDate(update?.reachedAt);
  const time = useFormatTime(update?.reachedAt);
  return (
    <div
      className={`flex justify-evenly w-full pl-2 pr-1 sm:p-0 text-sm sm:text-base ${
        index === 0 ? "border-0 border-solid border-t" : ""
      } `}
    >
      <div className={"min-w-[100px] "}>{update ? date : "Date"}</div>
      <div className={"min-w-[80px]"}>{update ? time : "Time"}</div>
      <div className={"w-[300px]"}>
        {update ? update?.city + " Reached" : "Update"}
      </div>
    </div>
  );
}
export default ItemTrecking;
