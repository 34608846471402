import React from "react";
import { TbTruckDelivery } from "react-icons/tb";

function Logo() {
  return (
    <div
      className="flex space-x-2 items-center cursor-pointer  "
      onClick={() => {}}
    >
      <TbTruckDelivery size={40} />
      <div className="flex flex-col">
        <div className="text-base font-semibold leading-5 w-36">
          Demo SuperFast
        </div>
        <div>
          <span className="text-xs font-medium">Delivery</span>
        </div>
      </div>
    </div>
  );
}

export default Logo;
