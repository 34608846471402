import { Button } from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState } from "react";
import InputFieldWithLabel, {
  AutocompleteWithLabel,
} from "../../components/inputfieldwithlabel";
import Vehicle from "../../components/vehicle";

import { Countries } from "../../utils/countries-list";
import { VehicleProps } from "../../utils/vehicle";
import { useNewBookingRequestEnquiry } from "./_data/user_hook";

function VehiclePage() {
  const vehicles: VehicleProps[] = [
    {
      id: "1",
      name: "TATA Ace Chota Hathi",
      type: "Manual",
      seat: 2,
      fuelType: "CNG",
      rate: 20,
      capacity: 710,
      image:
        "https://www.drivespark.com/car-image/640x480x100/car/x45282770-tata_ace.jpg.pagespeed.ic.MiYJtoPsxv.jpg",
    },
    {
      id: "2",
      name: "Mahindra Bolero Pikup ajkfhksd kajf kjsaf ksdf ",
      type: "Manual",
      seat: 2,
      fuelType: "Petrol",
      rate: 30,
      capacity: 1700,
      image:
        "https://prog-ace-cdn.azureedge.net/-/media/project/mahindra/dotcom/mahindra/gallery-brandpage/bolero-pik-up/30.jpg?rev=de879ba7fafc491fbd385e40c6b2349c",
    },
    {
      id: "3",
      name: "Eicher Truck 6 Wheeler",
      type: "Manual",
      seat: 2,
      fuelType: "CNG",
      rate: 35,
      capacity: 9500,
      image:
        "https://cms.eichertrucksandbuses.com/uploads/truck/sub-category/9a89ce245868a3d119545660854ed708.png",
    },
  ];
  const [index, setIndex] = React.useState<number>(10);

  const form = useForm({
    initialValues: {
      fromCountry: "",
      fromState: "",
      fromCity: "",
      //
      toCountry: "",
      toState: "",
      toCity: "",
      //
      phone: "",
    },

    validate: {
      // email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const addNewBookingReq = useNewBookingRequestEnquiry();

  return (
    <div className="flex flex-col max-w-4xl mx-auto items-center justify-center ">
      <div className="mt-20 font-bold text-xl sm:text-3xl xl:text-5xl">
        Ready for Logistics Shipment?
      </div>
      <div className="mt-3 font-bold text-lg sm:text-xl xl:text-2xl">
        Fill the form below to get started
      </div>
      <div className="flex flex-col justify-center items-center border border-solid rounded-lg w-full mt-10 pb-2">
        <div className="font-semibold mt-10 mb-5">
          Choose your Logistics Carrier
        </div>

        <form
          onSubmit={form.onSubmit(async (values) => {
            //
            await addNewBookingReq.fn({
              ...values,
              truckType: index >= 0 && index <= 2 ? vehicles[index].name : "",
            });
            setIsSubmitted(true);
            //
          })}
        >
          {isSubmitted ? (
            <div className="p-5">
              Your request has been submitted. We will get back to you soon.
            </div>
          ) : (
            <>
              <div className="flex flex-wrap justify-evenly items-center w-full ">
                {vehicles.map((vehicle, i) => (
                  <Vehicle
                    vehicle={vehicle}
                    selected={index === i}
                    onClick={() => {
                      setIndex(index === i ? 10 : i);
                    }}
                  />
                ))}
              </div>
              <div className="flex justify-evenly items-center flex-col sm:flex-row mt-2 w-full ">
                <div className="flex flex-col justify-center items-center  max-w-sm">
                  <div className="font-bold mb-4">Shiping From</div>
                  <AutocompleteWithLabel
                    title="Country"
                    options={Countries}
                    defaultValue={Countries[0]}
                    required
                    {...form.getInputProps("fromCountry")}
                  />
                  <InputFieldWithLabel
                    label="State"
                    {...form.getInputProps("fromState")}
                    required
                  />
                  <InputFieldWithLabel
                    label="City"
                    {...form.getInputProps("fromCity")}
                    required
                  />
                  <InputFieldWithLabel
                    label="Phone"
                    type="number"
                    placeholder="required"
                    {...form.getInputProps("phone")}
                    required
                  />
                </div>
                <div className="flex flex-col justify-center items-center  my-4 max-w-sm">
                  <div className="font-bold mb-4">Shipping to</div>
                  <AutocompleteWithLabel
                    title="Country"
                    options={Countries}
                    defaultValue={Countries[0]}
                    required
                    {...form.getInputProps("toCountry")}
                  />
                  <InputFieldWithLabel
                    label="State"
                    {...form.getInputProps("toState")}
                  />
                  <InputFieldWithLabel
                    label="City"
                    {...form.getInputProps("toCity")}
                  />
                  <Button
                    disabled={addNewBookingReq.loading}
                    loading={addNewBookingReq.loading}
                    type="submit"
                    className="rounded-full py-1 px-20 mt-2 h-[30px] w-full"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </>
          )}
          {/*  */}
        </form>
      </div>
    </div>
  );
}

export default VehiclePage;
