import { Select, SelectItem } from "@mantine/core";
import React from "react";

function Dropdown(props: {
  required?: boolean;
  placeholder?: string;
  options: (string | SelectItem)[];
  defaultValue?: string;
  searchable?: boolean;
  className?: string;
  readonly?: boolean;
  Rref?: React.Ref<any>;
  onChange?: (value: string) => void;
}) {
  return (
    <div className=" w-full">
      <Select
        {...props}
        className={props.className}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        readOnly={true}
        required={props.required}
        styles={{
          wrapper: {
            width: "100%",
          },
          input: {
            border: "none",
            paddingLeft: "10px",
            height: "0px",
            minHeight: "25px",
            cursor: "default",
          },
        }}
        data={props.options}
        searchable={props.searchable}
        onChange={props.onChange}
      />
    </div>
  );
}

export default Dropdown;
