import { enumKey } from "../../../utils/misc";
import { LocationStatus } from "../../admin/troutes/_data/model/troute";

export const PackageLocationStatus = (props: { status: LocationStatus }) => {
  const warningColor = "#ffc107";
  const infoColor = "#17a2b8";
  const successColor = "#28a745";
  const errorColor = "#dc3545";
  const color = (() => {
    switch (props.status) {
      case LocationStatus.NotYetDispatched:
        return warningColor;
      case LocationStatus.Dispatched:
        return infoColor;
      case LocationStatus.Reached:
        return infoColor;
      case LocationStatus.Delivered:
        return successColor;
      default:
        return infoColor;
    }
  })();

  return (
    <span
      className="text-xs px-[8px] py-[3px] text-text rounded-full w-fit"
      style={{
        backgroundColor: color,
      }}
    >
      {enumKey(LocationStatus, props.status)}
    </span>
  );
};
