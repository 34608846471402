import { Alert, Button, TextInput } from "@mantine/core";
import React from "react";
import Logo from "./logo";
import { AiOutlineArrowRight } from "react-icons/ai";
import {
  useAuthState,
  useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { useForm } from "@mantine/form";
import { BiErrorCircle } from "react-icons/bi";
import MainHeader from "./Header";
import Footer from "./footer";

export const AdminAuthContainer = (props: { children: React.ReactNode }) => {
  const [user, loading, error] = useAuthState(getAuth());
  const [signInWithEmailAndPassword, _user, _loading, _error] =
    useSignInWithEmailAndPassword(getAuth());

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) =>
        value.length < 6 ? "Password must be at least 6 characters" : null,
    },
  });

  return user || _user ? (
    <>{props.children}</>
  ) : (
    <>
      <MainHeader />
      <div
        className={
          " px-auto  w-full h-full bg-cover   bg-[url('https://img.freepik.com/free-vector/delivery-service-with-masks-concept_23-2148498421.jpg?w=740&t=st=1659261990~exp=1659262590~hmac=26a6fa23b1c1d81a1664f11f22b78dd0da002ea76e4627d543921dff826e166c')]"
        }
      >
        <div className="h-full pt-16 md:pt-24">
          <div
            className=" text-black/80 w-fit px-8 py-8  bg-gradient-to-b from-white/50 
          via-white/80 to-white mx-auto rounded-xl backdrop-blur-lg shadow-lg "
          >
            <div className="">
              <Logo />
            </div>
            <div className="flex flex-col space-y-2 pt-6 w-full items-center max-w-[350px] px-4">
              <div className="text-sm ">Admin Login</div>

              <form
                onSubmit={form.onSubmit((values) => {
                  signInWithEmailAndPassword(values.email, values.password);
                })}
                className="flex flex-col space-y-2"
              >
                <TextInput
                  disabled={loading || _loading}
                  placeholder="Email"
                  className="pt-4"
                  {...form.getInputProps("email")}
                />
                <TextInput
                  disabled={loading || _loading}
                  placeholder="Password"
                  type={"password"}
                  {...form.getInputProps("password")}
                />
                <Button
                  type="submit"
                  className="w-full"
                  rightIcon={<AiOutlineArrowRight />}
                  loading={loading || _loading}
                >
                  Login
                </Button>
                {(error || _error) && (
                  <Alert
                    icon={<BiErrorCircle size={16} />}
                    title="Error!"
                    color="red"
                  >
                    <div className="text-xs">Wrong email or password</div>
                  </Alert>
                )}
                <Button
                  className="w-full"
                  rightIcon={<AiOutlineArrowRight />}
                  loading={loading || _loading}
                  onClick={() => {
                    signInWithEmailAndPassword("admin@super.com", "123123123");
                  }}
                >
                  Demo Login
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
