import { Button, Modal, MultiSelect } from "@mantine/core";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TRouteStatus } from "../_data/model/troute";
import {
  useDeleteRoute,
  useGetRouteByID,
  useMakeDelivered,
  useMakeDispatched,
  useUpdateRouteLocation,
} from "../_data/troutes_hook";
import { TRouteStatusComponent } from "./troute_status";

export const TrouteDetailView = () => {
  const navigate = useNavigate();
  const id = useParams()["id"];

  const route = useGetRouteByID(id);
  const makeDispatch = useMakeDispatched();
  const updateLocation = useUpdateRouteLocation();
  const makeDelivered = useMakeDelivered();
  const deleteRoute = useDeleteRoute();

  const [locationUpdate, setLocationUpdate] = useState<
    { value: string; label: string }[]
  >((route.data?.route ?? []).map((l) => ({ value: l.city, label: l.city })));

  return (
    <Modal
      opened
      onClose={() => {
        navigate(-1);
      }}
      title={
        <div className="flex space-x-2 items-center">
          {route.data && (
            <>
              <TRouteStatusComponent status={route.data!.status} />

              {route.data!.status === TRouteStatus.Delivered && (
                <Button
                  color={"red"}
                  disabled={deleteRoute.loading}
                  loading={deleteRoute.loading}
                  onClick={async () => {
                    await deleteRoute.fn(route.data!.id!);
                    navigate(-1);
                  }}
                >
                  Delete Route
                </Button>
              )}
            </>
          )}
        </div>
      }
    >
      {route.loading ||
      makeDispatch.loading ||
      updateLocation.loading ||
      deleteRoute.loading ||
      makeDelivered.loading ? (
        <div>Loading...</div>
      ) : (
        <div className="flex flex-col space-y-2">
          <div className="flex flex-col border border-solid border-text2 p-1 rounded-md">
            <div>ID : {route.data?.routeID}</div>
            <div>Name : {route.data?.name ?? "NO NAME"}</div>
            <div>
              Description : {route.data?.description ?? "NO DESCRIPTION"}
            </div>
          </div>
          <div className="flex flex-col border border-solid border-text2 p-1 rounded-md">
            {route.data!.route.map((l, i) => {
              return (
                <div key={l.city}>
                  {i === 0
                    ? "FROM : "
                    : i === route.data!.route.length - 1
                    ? "TO : "
                    : "VIA :"}{" "}
                  {l.city}
                </div>
              );
            })}
          </div>

          {/* Pacakges */}

          <div>{route.data?.packages.length} Packages</div>

          {/* If Not Yet Dispatched */}
          {route.data!.status === TRouteStatus.NotYetDispatched && (
            <div className="pt-2 flex space-x-2 items-end">
              <Button
                onClick={() => {
                  makeDispatch.fn(route.data!, {
                    city: route.data!.source.city,
                    reachedAt: new Date(),
                  });
                }}
              >
                Make Dispatched
              </Button>
            </div>
          )}
          {/* If Ongoing */}
          {route.data!.status === TRouteStatus.OnGoing && (
            <>
              <div className="pt-2 flex space-x-2 items-end">
                <MultiSelect
                  label="Update Last Location"
                  data={locationUpdate}
                  placeholder="Select Location"
                  searchable
                  className="w-full"
                  maxSelectedValues={1}
                  creatable
                  getCreateLabel={(query) => `+ Add ${query}`}
                  onCreate={(query) => {
                    const item = { value: query, label: query };
                    setLocationUpdate((current) => [...current, item]);
                    return item;
                  }}
                />
                <Button
                  onClick={() => {
                    updateLocation.fn(route.data!, {
                      city: locationUpdate[0].value,
                      reachedAt: new Date(),
                    });
                  }}
                >
                  Update Location
                </Button>
              </div>
              <Button
                onClick={() => {
                  makeDelivered.fn(route.data!, {
                    city: locationUpdate[0].value,
                    reachedAt: new Date(),
                  });
                }}
              >
                Make Delivered
              </Button>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};
