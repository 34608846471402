import React from "react";
import { VehicleProps } from "../utils/vehicle";

function Vehicle(props: {
  vehicle: VehicleProps;
  onClick?: () => void;
  selected?: boolean;
}) {
  return (
    <div
      onClick={props.onClick}
      className={`flex cursor-pointer transition-all ease-in-out duration-50 ${
        props.selected
          ? "outline-accentShade outline-2 outline "
          : "hover:outline-offset-2 outline-accentShade hover:outline-1 hover:outline"
      }   
    rounded-lg w-[400px] h-[150px] border-accentShade border border-solid overflow-clip mt-2`}
    >
      <div className="flex bg-auto border-0 border-r border-solid border-onbackground">
        <img
          src={props.vehicle.image}
          alt=""
          height={100}
          width={200}
          className="object-contain m-auto h-full"
        />
      </div>
      <div className="flex flex-col w-[200px]  px-1 mt-1 text-sm">
        <div className="font-semibold truncate">{props.vehicle.name}</div>
        <div>{"Type: " + props.vehicle.type}</div>
        <div>{"Seats: " + props.vehicle.seat}</div>
        <div>{"Fuel Type: " + props.vehicle.fuelType}</div>
        <div>{"Rate: " + props.vehicle.rate + "/Km"}</div>
        <div>{"Capacity: " + props.vehicle.capacity + "Kgs"}</div>
      </div>
    </div>
  );
}

export default Vehicle;
