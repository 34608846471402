import { async } from "@firebase/util";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { TRoute } from "../../admin/troutes/_data/model/troute";
import { Package } from "./_models/package";

export async function fetchAllPackages(
  limitedBy: number = 50
): Promise<Package[]> {
  const fs = getFirestore();
  const col = collection(fs, "packages");
  const q = query(col, orderBy("createdAt", "desc"), limit(limitedBy));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    } as Package;
  });
}

export async function createNewPackage(cPackage: Package): Promise<string> {
  const fs = getFirestore();
  const col = collection(fs, "packages");
  const d = await setDoc(doc(col, cPackage.id), {
    ...cPackage,
    createdAt: new Date(),
    phone: cPackage.owner.phone,
  });

  // get routes
  const col2 = collection(fs, "routes");
  const d2 = await getDoc(doc(col2, cPackage.routeID));
  const route = d2.data() as TRoute;

  // add pacakge to route
  await updateDoc(doc(col2, cPackage.routeID), {
    packages: [...route.packages, cPackage.id],
  });

  return cPackage.id;
}

export async function fetchPackageByID(id: string): Promise<Package> {
  const fs = getFirestore();
  const col = collection(fs, "packages");

  const d = await getDoc(doc(col, id));
  if (!d.exists()) {
    throw new Error("Package not found");
  }
  return { ...d.data(), id: d.id } as Package;
}

export async function fetchPackageByPhoneNumber(
  phoneNumber: string
): Promise<Package> {
  const fs = getFirestore();
  const col = collection(fs, "packages");
  const q = query(
    col,
    where("phone", "==", phoneNumber),
    orderBy("createdAt", "desc"),
    limit(1)
  );
  const d = await getDocs(q);
  if (d.empty) {
    throw new Error("Package not found");
  }
  return { ...d.docs[0].data(), id: d.docs[0].id } as Package;
}

export async function fetchAllPackagesByRouteID(
  routeID: string
): Promise<Package[]> {
  const fs = getFirestore();
  const col = collection(fs, "routes");
  const r = (await getDoc(doc(col, routeID))).data() as TRoute;
  const packagesID = r!.packages;
  const col2 = collection(fs, "packages");
  // get all packages by id
  const q = query(col2, where("id", "in", packagesID));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Package));
}
