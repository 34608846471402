import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export interface ButtonProps {
  title: string;
  path: string;
}

function MenuButtons({ list }: { list: ButtonProps[] }) {
  const location = useLocation();
  function checkLocation(path: string) {
    return location.pathname.includes(path);
  }
  const navigate = useNavigate();
  return (
    <div className="relative">
      <div className="flex">
        {list.map((e) => (
          <div
            className={`px-3 py-1 ${
              checkLocation(e.path) ? "bg-accentShade text-background" : ""
            } rounded cursor-pointer`}
            onClick={() => {
              navigate(e.path);
            }}
          >
            <span>{e.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MenuButtons;
