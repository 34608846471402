import { SelectItem } from "@mantine/core";
import React from "react";
import Dropdown from "./dropdown";
import InputField from "./inputfield";

function InputFieldWithLabel(props: {
  label: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
}) {
  return (
    <div className="flex justify-between items-center space-x-2 mt-2 min-w-[300px] w-full">
      <div className="w-[150px]">{props.label}</div>
      <InputField
        {...props}
        type={props.type ?? "text"}
        placeholder={props.placeholder ?? ""}
        padding="0px 0px"
        borderRadius="4px"
        width="100%"
        required={props.required}
      />
    </div>
  );
}

function AutocompleteWithLabel(props: {
  options: (string | SelectItem)[];
  title: string;
  className?: string;
  defaultValue?: string;
  onChange?: (value: string) => void;
  required?: boolean;
}) {
  return (
    <div className="flex justify-between text-left mt-1 w-full min-w-[300px]">
      <span className=" w-[150px] ">{props.title}</span>
      <Dropdown
        {...props}
        options={props.options}
        className={props.className}
        defaultValue={props.defaultValue}
        searchable={true}
        onChange={props.onChange}
        required={props.required}
      />
    </div>
  );
}

export default InputFieldWithLabel;
export { AutocompleteWithLabel };
