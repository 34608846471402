import React from "react";
import { BiPackage } from "react-icons/bi";
import { FaTruckMoving } from "react-icons/fa";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import SearchItem from "./item-tracking-components/search-item";

function Track() {
  return (
    <div
      className={
        "flex flex-col justify-center items-center w-full max-w-4xl mx-auto "
      }
    >
      <div className="mt-20 font-bold text-3xl sm:text-4xl xl:text-5xl">
        Track your Shipment
      </div>

      <SearchItem />
      <div className="flex flex-col justify-center items-center mt-8 mb-8 font-bold">
        <div className="text-xl">King Super Fast</div>
        <div>Delivery Services at your doorsteps</div>
      </div>
      <div className="flex flex-col sm:flex-row">
        <FeatureBox
          title="Document & Parcel"
          link="/shipment"
          description="Shiping small size parcels & documents to any destination with our
          safty & super fast delivery"
          icon={<BiPackage size={50} className="text-accentShade" />}
        />
        <FeatureBox
          title="Logistics"
          link="/logistics"
          description="Large size shipments are available for safe & fast delivery to any 
          destinations Enquire today via our contact form"
          icon={<FaTruckMoving size={50} className="text-accentShade" />}
        />
      </div>
    </div>
  );
}

function FeatureBox({
  title,
  description,
  icon,
  link,
}: {
  title: string;
  description: string;
  icon: React.ReactElement;
  link: string;
}) {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center border-solid border ml-2 mt-3 border-onbackground rounded-lg p-2 max-w-sm">
      {icon}
      <div className="font-bold">{title}</div>
      <div className="text-base text-center mt-2">{description}</div>
      <div
        onClick={() => {
          navigate(link);
        }}
        className="flex justify-center items-center text-sm underline-none text-primary cursor-pointer hover:space-x-2 my-2"
      >
        <span>learn more</span>
        <BsArrowRightShort
          className="transition-all ease-in-out duration-300"
          size={20}
        />
      </div>
    </div>
  );
}
export default Track;
