import React from "react";
import { useNavigate } from "react-router-dom";
import { TRoute } from "../_data/model/troute";
import { useAllTruckRoutes } from "../_data/troutes_hook";
import { TRouteStatusComponent } from "./troute_status";

export const TRoutesList = () => {
  const troutes = useAllTruckRoutes();
  const navigate = useNavigate();

  if (troutes.isLoading || troutes.error) {
    return <div>Loading...</div>;
  }

  console.log(troutes.data);

  return (
    <div className="grid grid-cols-2 sm:grid-cols-[repeat(auto-fill,_250px)]   gap-2 pt-6 w-full px-4 ">
      {troutes.data!.map((r) => {
        return (
          <TRouteItem
            tRoute={r}
            onClick={() => {
              navigate(`${r.id}`);
            }}
          />
        );
      })}
    </div>
  );
};

function TRouteItem(props: { tRoute: TRoute; onClick: () => void }) {
  return (
    <div
      className={`px-3 py-3 flex flex-col rounded-md bg-backgroundshade
        sm:min-w-[250px] sm:max-w-[250px] select-none cursor-pointer
        transition-all
        hover:bg-text2/30
         outline-[2px] outline-primary 
`}
      onClick={props.onClick}
    >
      {/* ID And Status */}
      <div className="flex items-center justify-between">
        <div className="flex flex-col ">
          <TRouteStatusComponent status={props.tRoute.status} />
          <span className="pt-2">{props.tRoute.routeID}</span>
          <div className="text-xs">{props.tRoute.name}</div>
        </div>
      </div>

      {/*  */}

      {/* Last Update */}

      <div className="h-6"></div>

      <div className="border-[2px] border-onbackgroundshade  border-solid rounded-md text-sm flex flex-col  p-1">
        <div>
          <span className="bg-background font-bold text-xs px-1 mr-1">
            FROM
          </span>
          {props.tRoute.source.city}
        </div>
        {props.tRoute.route.length > 2 && (
          <div>
            <span className="bg-background font-bold text-xs px-1 mr-1">
              VIA {props.tRoute.route.length - 2} STOP
            </span>
          </div>
        )}
        <div>
          <span className="bg-background font-bold text-xs px-1 mr-1">TO</span>
          {props.tRoute.destination.city}
        </div>
      </div>

      {/*  */}

      <div className="flex items-center justify-between mt-2">
        <div className="bg-background font-bold text-sm px-1  w-fit">
          {props.tRoute.packages.length} PACKAGES
        </div>
        {/* {props.tRoute.capacity > 0 && (
          <div className="text-sm">{props.tRoute.capacity}% FULL</div>
        )} */}
      </div>
    </div>
  );
}
