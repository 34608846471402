import {
  addDoc,
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { TRoute } from "./model/troute";

export async function fetchAllRoutes(
  limitedBy: number = 50
): Promise<TRoute[]> {
  const fs = getFirestore();
  const col = collection(fs, "routes");
  const q = query(col, orderBy("createdAt", "desc"), limit(limitedBy));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => {
    return {
      ...doc.data(),
      id: doc.id,
    } as TRoute;
  });
}

export async function createNewRoute(route: TRoute): Promise<string> {
  const fs = getFirestore();
  const col = collection(fs, "routes");
  const doc = await addDoc(col, {
    ...route,
    createdAt: new Date(),
  });
  return doc.id;
}
