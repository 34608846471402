import { Button, Text } from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LocationStatus } from "../admin/troutes/_data/model/troute";
import { TrackingCard } from "./components/tracking-card";
import { useAllPackages } from "./_data/package_hook";

export const TrackingHome = () => {
  const [selectedFilter, setSelectedFilter] = useState("All");

  const navigate = useNavigate();
  const path = useLocation();

  const packages = useAllPackages();

  //
  //

  if (packages.isLoading || packages.error) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex w-full">
      {/* Left List */}

      <div className="flex flex-col px-6 py-6 w-full ">
        <div className="flex items-center justify-between">
          <div className="text-xl font-semibold opacity-80">Packages</div>
          <Button
            onClick={() => {
              navigate(`new`);
            }}
          >
            Add New Package
          </Button>
        </div>

        {/* Filter */}
        <div className="flex flex-col pt-6 ">
          <span className="text-xs text-onBackgroundShade">Filter</span>
          <div className="flex space-x-2 pt-2">
            {["All", ...Object.keys(LocationStatus)].map((e) => (
              <Button
                key={e}
                size="xs"
                variant={selectedFilter === e ? "filled" : "default"}
                onClick={() => setSelectedFilter(e)}
              >
                {e}
              </Button>
            ))}
          </div>
        </div>

        {/*  */}

        {/* Main */}

        <div className="grid grid-cols-2 sm:grid-cols-[repeat(auto-fill,_250px)] gap-2 pt-6 w-full ">
          {(selectedFilter === "All"
            ? packages.data!
            : packages.data!.filter(
                (e) => e.status === (LocationStatus as any)[selectedFilter]
              )
          ).map((e) => {
            return (
              <TrackingCard
                key={e.id}
                package={e}
                onClick={() => {
                  navigate(`${e.id}`);
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
