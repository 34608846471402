import React from "react";
import Logo from "./logo";

function Footer() {
  const cc = "mr-4 hover:underline md:mr-6 cursor-pointer";
  return (
    <footer className="p-4 bg-backgroundShade rounded-t-lg shadow md:px-6 md:py-8 mt-20 ">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between justify-center items-center">
        <Logo />
        <div className="flex flex-wrap  mb-6 text-sm sm:mb-0 mt-4 md:mt-0">
          <span className={cc}>About</span>
          <span className={cc}>Privacy Policy</span>
          <span className="hover:underline cursor-pointer">Contact</span>
        </div>
      </div>
      {/* <hr className="my-4 border-onbackground sm:mx-auto  " /> */}
      <div className="flex text-sm text-gray-500 sm:text-center border-0 border-solid border-t pt-4 mt-4">
        © 2022 <span className="font-semibold">Demo Super Fast Delivery™</span>.
        All Rights Reserved.
      </div>
    </footer>
  );
}

export default Footer;
