import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useState } from "react";
import { Package } from "../../../tracking/_data/_models/package";
import { Location, TRoute, LocationStatus, TRouteStatus } from "./model/troute";
import { createNewRoute, fetchAllRoutes } from "./troutes_api";

export function useAllTruckRoutes(limitedBy?: number) {
  const q = useQuery(
    ["allTruckRoutes"],
    () => fetchAllRoutes(limitedBy ?? 50),
    {}
  );

  return {
    data: q.data,
    isLoading: q.isLoading,
    error: q.error,
  };
}

export function useAddNewRoute() {
  const [loading, setLoading] = useState(false);
  const qc = useQueryClient();
  const fn = async (route: TRoute) => {
    setLoading(true);

    const id = await createNewRoute(route);
    qc.invalidateQueries(["allTruckRoutes"]);
    setLoading(false);
    return id;
  };
  return {
    loading,
    fn,
  };
}

export function useGetRouteByID(id?: string) {
  const q = useAllTruckRoutes();
  const route = q.data?.find((r) => r.id === id);
  return {
    loading: q.isLoading,
    error: q.error,
    data: route,
  };
}

export function useDeleteRoute() {
  const fs = getFirestore();
  const [loading, setLoading] = useState(false);

  const qc = useQueryClient();
  const fn = async (id: string) => {
    setLoading(true);
    await deleteDoc(doc(fs, `routes/${id}`));
    qc.invalidateQueries(["allTruckRoutes"]);
    setLoading(false);
  };
  return {
    loading,
    fn,
  };
}

export function useUpdateRouteLocation() {
  const [loading, setLoading] = useState(false);
  const qc = useQueryClient();

  const fn = async (route: TRoute, location: Location) => {
    setLoading(true);
    const fs = getFirestore();
    const col = collection(fs, "routes");
    await updateDoc(doc(col, route.id), {
      locationStatus: LocationStatus.Reached,
      location: [...(route.locations ?? []), location],
      status: TRouteStatus.OnGoing,
    });
    //
    // update all pacakges
    const pcol = collection(fs, "packages");
    const pq = query(pcol, where("id", "in", route.packages));
    const pdocs = await getDocs(pq);
    await pdocs.docs.forEach(async (p) => {
      return await updateDoc(doc(pcol, p.id), {
        ...p.data(),
        status: LocationStatus.Reached,
        locations: [...p.data().locations, location],
      });
    });
    qc.invalidateQueries(["allTruckRoutes"]);
    qc.invalidateQueries(["allPackages"]);
    qc.invalidateQueries(["routePackages", route.id]);
    setLoading(false);
    return true;
  };

  return {
    loading: loading,
    fn: fn,
  };
}

export function useMakeDispatched() {
  const [loading, setLoading] = useState(false);
  const qc = useQueryClient();

  const fn = async (route: TRoute, location: Location) => {
    setLoading(true);
    const fs = getFirestore();
    const col = collection(fs, "routes");
    await updateDoc(doc(col, route.id), {
      locationStatus: LocationStatus.Dispatched,
      location: [...(route.locations ?? []), location],
      status: TRouteStatus.OnGoing,
    });
    //
    // update all pacakges
    const pcol = collection(fs, "packages");
    const pq = query(pcol, where("id", "in", route.packages));
    const pdocs = await getDocs(pq);
    await pdocs.docs.forEach(async (p) => {
      return await updateDoc(doc(pcol, p.id), {
        ...p.data(),
        status: LocationStatus.Dispatched,
        locations: [...p.data().locations, location],
      });
    });
    qc.invalidateQueries(["allTruckRoutes"]);
    qc.invalidateQueries(["allPackages"]);
    qc.invalidateQueries(["routePackages", route.id]);
    setLoading(false);
    return true;
  };

  return {
    loading: loading,
    fn: fn,
  };
}

export function useUpdateItemStatus() {
  const [loading, setLoading] = useState(false);
  const qc = useQueryClient();

  const detach = async (route: string, pid: string) => {
    setLoading(true);
    const fs = getFirestore();
    const col = collection(fs, "routes");
    const r = await getDoc(doc(col, route));
    const r1 = { ...r.data(), id: r.id } as TRoute;
    await updateDoc(doc(col, r1.id), {
      packages: r1.packages.filter((p) => p !== pid),
    });
    // update doc
    const pkgcol = collection(fs, "packages");
    await updateDoc(doc(pkgcol, pid), {
      routeID: null,
    });
  };

  const updateItem = async (
    pid: string,
    location: Location,
    s: LocationStatus
  ) => {
    setLoading(true);
    const fs = getFirestore();

    const pkgcol = collection(fs, "packages");
    const pdocs = await getDoc(doc(pkgcol, pid));
    await updateDoc(doc(pkgcol, pid), {
      status: s,
      locations: [...pdocs.data()!.locations, location],
    });

    qc.invalidateQueries(["allPackages"]);
    setLoading(false);
    return true;
  };

  return {
    loading: loading,
    fn: updateItem,
    detach: detach,
  };
}

export function useMakeDelivered() {
  const [loading, setLoading] = useState(false);
  const qc = useQueryClient();

  const fn = async (route: TRoute, location: Location) => {
    setLoading(true);
    const fs = getFirestore();
    const col = collection(fs, "routes");
    await updateDoc(doc(col, route.id), {
      locationStatus: LocationStatus.Delivered,
      location: [...(route.locations ?? []), location],
      status: TRouteStatus.Delivered,
    });
    //
    // update all pacakges
    const pcol = collection(fs, "packages");
    const pq = query(pcol, where("id", "in", route.packages));
    const pdocs = await getDocs(pq);
    await pdocs.docs.forEach(async (p) => {
      return await updateDoc(doc(pcol, p.id), {
        ...p.data(),
        status: LocationStatus.Delivered,
        locations: [...p.data().locations, location],
      });
    });
    qc.invalidateQueries(["allTruckRoutes"]);
    qc.invalidateQueries(["allPackages"]);
    qc.invalidateQueries(["routePackages", route.id]);
    setLoading(false);
    return true;
  };

  return {
    loading: loading,
    fn: fn,
  };
}
