import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Divider,
  Modal,
  NativeSelect,
  Text,
  TextInput,
  Timeline,
} from "@mantine/core";
import { HiLocationMarker } from "react-icons/hi";
import { useGetPackageByID } from "./_data/package_hook";
import { PackageLocationStatus } from "./components/tracking-status";
import dayjs from "dayjs";
import {
  useAllTruckRoutes,
  useUpdateItemStatus,
} from "../admin/troutes/_data/troutes_hook";
import {
  LocationStatus,
  TRouteStatus,
} from "../admin/troutes/_data/model/troute";
import { BiMessage } from "react-icons/bi";
import { openConfirmModal } from "@mantine/modals";
import useSendMessage from "../../components/useSendMessage";
import SendSMSUi from "./components/sendSMSUi";

export const TrackingDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;

  const cpackage = useGetPackageByID(id);
  const item = useUpdateItemStatus();
  const LocationRef = React.useRef<HTMLInputElement>(null);
  const RouteRef = React.useRef<HTMLSelectElement>(null);
  const routes = useAllTruckRoutes();
  const [locationError, setLocationError] = React.useState("");
  //
  const sms = useSendMessage();
  const op = cpackage?.data?.owner.phone ?? "";
  const phone = op.startsWith("+91") && op.length === 13 ? op : "+91" + op;

  let msg = `Your package ${cpackage.data?.id} has been Dispatched from ${cpackage.data?.source.city}.
  To track your package visit our website
  https://kingsuperfast.com/track`;

  const openModal = () =>
    openConfirmModal({
      zIndex: 1000,
      title: "Send an Update to Owner",
      children: <SendSMSUi pkg={cpackage.data!} />,
      labels: { confirm: "Whatsapp", cancel: "Text" },
      onCancel: () => sms.sms(phone, msg),
      onConfirm: () => sms.whatsApp(phone, msg),
    });

  return (
    <Modal
      opened
      onClose={() => {
        navigate(-1);
      }}
    >
      {cpackage.isLoading || cpackage.error ? (
        <div>Laoding...</div>
      ) : (
        <div className="w-full h-full">
          <div className="h-full w-full ">
            {/*  */}

            {/* Head */}

            <div className="p-1">
              <div className="flex items-center text-base text-onBackground  space-x-4">
                <div className="text-3xl">{id}</div>

                <PackageLocationStatus status={cpackage.data!.status} />
              </div>
              <div className="font-semibold pt-4">
                From : {cpackage.data?.owner.name ?? "No Name"}{" "}
                {`(${cpackage.data?.source.city})`}
              </div>
              <Button
                onClick={() => {
                  openModal();
                }}
                className="bg-transparent text-onBackground items-center font-medium hover:bg-backgroundShade w-fit px-2 h-6 rounded-lg"
              >
                <BiMessage className="mr-2" />
                <span>{cpackage.data?.owner.phone ?? "No Phone"}</span>
              </Button>

              <div className="">
                {cpackage.data?.owner.address ?? "No Address"}
              </div>
              <div className="font-semibold pt-4">
                To : {cpackage.data?.recipient.name ?? "No Name"}{" "}
                {`(${cpackage.data?.destination.city})`}
              </div>
              <Button className="bg-transparent text-onBackground items-center font-medium hover:bg-backgroundShade w-fit px-2 h-6 rounded-lg">
                <BiMessage className="mr-2" />
                <span>{cpackage.data?.recipient.phone ?? "No Phone"}</span>
              </Button>
              <div className="">
                {cpackage.data?.recipient.address ?? "No Address"}
              </div>
              <div className="text-xs mt-2">
                Route: {cpackage.data?.routeID ? "Autometic" : "Manual"}
              </div>
            </div>

            {/*  */}

            <Divider className="opacity-30" />

            {/* LOCATION */}
            <div
              className={`mt-2 w-full flex items-end ${
                cpackage.data?.status === "dispatched" ||
                cpackage.data?.status === "reached"
                  ? ""
                  : "hidden"
              }`}
            >
              <TextInput
                className="w-full mr-2"
                label="Location"
                ref={LocationRef}
                required
                onChange={(e) => {
                  setLocationError("");
                }}
                error={locationError}
                placeholder="eg. Delhi"
              />
              <Button
                onClick={() => {
                  if (LocationRef.current!.value.length > 2) {
                    item.fn(
                      cpackage.data!.id,
                      {
                        city: LocationRef.current!.value,
                      },
                      LocationStatus.Reached
                    );
                  } else {
                    setLocationError("Please enter longer name");
                  }
                }}
              >
                Update
              </Button>
            </div>

            {/* ADD TO ROUTE */}
            <div
              className={`mt-2 w-full flex items-end ${
                cpackage.data?.routeID !== "" && cpackage.data?.routeID
                  ? "hidden"
                  : ""
              }`}
            >
              <NativeSelect
                data={routes
                  .data!.filter(
                    (e) => e.status === TRouteStatus.NotYetDispatched
                  )
                  .map((r) => ({
                    value: r.id!,
                    label: `${r.name ?? ""} (${r.routeID})`,
                  }))}
                label="Route"
                className="w-full mr-2"
                placeholder="Pick a route"
                ref={RouteRef}
              />
              <Button onClick={() => {}}>Add To Route</Button>
            </div>

            {/* GOTO ROUTE & DETACH */}
            <div className={`my-3 w-full px-6  flex flex-wrap items-end `}>
              <Button
                className={`mt-2 mr-2  ${
                  cpackage.data?.routeID !== "" && cpackage.data?.routeID
                    ? ""
                    : "hidden"
                }`}
                onClick={() => {
                  navigate(`/admin/truckRoutes/${cpackage.data?.routeID}`);
                }}
              >
                Go to Route
              </Button>
              <Button
                className={`mt-2 mr-2 ${
                  cpackage.data?.routeID !== "" && cpackage.data?.routeID
                    ? ""
                    : "hidden"
                }`}
                variant="outline"
                onClick={() => {
                  item.detach(cpackage.data!.routeID!, cpackage.data!.id);
                  navigate(`/admin/tracking/`);
                }}
              >
                Detach Item
              </Button>
              {/* MAKE DELIVERED */}
              <Button
                className={`mt-2 mr-2  flex items-end ${
                  cpackage.data?.status === "dispatched" ||
                  cpackage.data?.status === "reached"
                    ? ""
                    : "hidden"
                }`}
                onClick={() => {
                  item.fn(
                    cpackage.data!.id,
                    { city: cpackage.data!.destination.city },
                    LocationStatus.Reached
                  );
                }}
              >
                Make Delivered
              </Button>
              <Button
                className={`mt-2 mr-2 ${
                  cpackage.data?.status === "not_yet_dispatched" ? "" : "hidden"
                }`}
                variant="outline"
                onClick={() => {
                  item.fn(
                    cpackage.data!.id,
                    cpackage.data!.source,
                    LocationStatus.Dispatched
                  );
                  navigate(`/admin/tracking/`);
                }}
              >
                Dispatch Item
              </Button>
            </div>

            {/*  */}

            <Divider className="opacity-30" />

            {/*  */}

            {/*  */}

            <div className="p-6">
              <Timeline bulletSize={24} lineWidth={2}>
                {cpackage.data!.locations!.map((e, i) => {
                  return (
                    <Timeline.Item
                      key={i}
                      bullet={<HiLocationMarker size={12} />}
                      title={e.city}
                      styles={{}}
                    >
                      <div className="text-sm text-onbackgroundshade">
                        {e.address ?? ""}
                      </div>
                      <div className="text-sm text-onbackgroundshade">
                        {dayjs(e.reachedAt).format("DD-MMM-YY - hh:mm:ss A") ??
                          ""}
                      </div>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
