import { Input, SelectItem, Styles } from "@mantine/core";
import React from "react";
import { MdArrowDropDown } from "react-icons/md";

function InputField(props: {
  required?: boolean;
  type: string;
  placeholder?: string;
  borderRadius?: string;
  border?: string;
  padding?: string;
  height?: string;
  width?: string;
  className?: string;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
}) {
  const inputStyle:
    | Styles<
        | "input"
        | "icon"
        | "disabled"
        | "wrapper"
        | "withIcon"
        | "invalid"
        | "rightSection",
        never
      >
    | undefined = {
    input: {
      borderRadius: props.borderRadius ?? "999px",
      border: props.border ?? "1px solid #555",
      padding: props.padding ?? "0px 0px",
      height: props.height ?? "20px",
      textAlign: props.textAlign ?? "match-parent",
      ":focus": {
        borderColor: "#555",
      },
    },
    wrapper: {
      width: props.width ?? "100%",
      padding: "0px 0px",
    },
  };
  return (
    <Input
      {...props}
      required={props.required}
      type={props.type}
      placeholder={props.placeholder}
      variant="unstyled"
      styles={inputStyle}
      className={props.className}
    ></Input>
  );
}

function InputSelect(props: {
  placeholder: string;
  borderRadius?: string;
  border?: string;
  padding?: string;
  textAlign?:
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "justify"
    | "match-parent";
  component: "select" | "input" | "textarea" | "button";
  options?: (string | SelectItem)[];
}) {
  return (
    <Input component="select" rightSection={<MdArrowDropDown size={14} />}>
      <option value="1">1</option>
      <option value="2">2</option>
    </Input>
  );
}

export default InputField;
export { InputSelect };
