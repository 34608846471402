import { GiPathDistance } from "react-icons/gi";
import { Divider } from "@mantine/core";
import { Link, useLocation } from "react-router-dom";
import { BiPackage } from "react-icons/bi";
import Logo from "../../../components/logo";
import { CgProfile } from "react-icons/cg";

const items = [
  {
    icon: GiPathDistance,
    label: "Routes",
    to: "truckRoutes",
  },
  {
    icon: BiPackage,
    label: "Packages",
    to: "tracking",
  },
  {
    icon: BiPackage,
    label: "Shipments",
    to: "shipments",
  },
  {
    icon: BiPackage,
    label: "Requests",
    to: "bookingRequests",
  },
  {
    icon: CgProfile,
    label: "profile",
    to: "profile",
  },
];

export const AdminSideBar = (props: { onAddNewTracking: () => void }) => {
  const location = useLocation();
  return (
    <>
      {/* VERTICAL */}
      <div className="min-w-[220px] max-w-[220px]">
        <div className=" hidden sm:flex flex-col bg-backgroundshade min-w-[220px] max-w-[220px] left-0 fixed h-full select-none">
          <div className="p-4 pt-8">
            <Logo />
          </div>
          <Divider className="opacity-25" />
          <div className="h-6"></div>
          {/* Menu Items */}

          {items.map((e) => {
            const isActive = location.pathname.includes(e.to);
            return (
              <Link
                key={e.label}
                to={e.to}
                className={`flex   font-bold items-center
            cursor-pointer select-none transition-all   ${
              isActive
                ? `text-background bg-accentShade `
                : `text-onbackgroundshade hover:text-accentShade hover:bg-primary/20 `
            } `}
                style={{
                  textDecoration: "none",
                }}
              >
                <div className="space-x-4 items-center px-4 py-3 flex">
                  <e.icon size={20} />
                  <div className=" text-xs tracking-wide">{e.label}</div>
                </div>
              </Link>
            );
          })}

          {/*  Bottom */}
          <div className="flex-grow"></div>
          <div className="p-4"></div>
        </div>
      </div>

      {/*  */}

      {/* HORIZONTAL */}

      <div className=" flex sm:hidden flex-col bg-bg2 min-w-[220px] select-none ">
        {/* Logo */}

        <div className="relative w-full h-10 ">
          <div className="flex justify-center space-x-2  px-8 py-3 items-center text-onbackground ">
            <div className="text-sm font-semibold leading-5">
              Demo SuperFast
            </div>
          </div>
        </div>

        <Divider className="opacity-25" />
        {/* Menu Items */}
        <div className="w-full flex fixed bg-background bottom-0 ">
          {items.map((e) => {
            const isActive = location.pathname.includes(e.to);
            return (
              <Link
                key={e.label}
                to={e.to}
                className={`flex flex-col  w-full  items-center   font-bold
              cursor-pointer select-none transition-all relative   ${
                isActive
                  ? `text-white bg-primary`
                  : `text-onbackgroundshade hover:text-primary hover:bg-primary/20 `
              } `}
                style={{
                  textDecoration: "none",
                }}
              >
                <div className="flex flex-col py-2 space-y-2 items-center">
                  <e.icon size={20} />
                  <div className=" text-xs text-center tracking-wide">
                    {e.label}
                  </div>
                </div>
              </Link>
            );
          })}

          {/*  */}
        </div>

        {/*  */}
        <Divider className="opacity-25" />
      </div>
    </>
  );
};
