import {
  ActionIcon,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  MultiSelect,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React, { useState } from "react";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { Location, LocationStatus, TRouteStatus } from "../_data/model/troute";
import { useAddNewRoute } from "../_data/troutes_hook";

export const TrouteNewForm = () => {
  const navigate = useNavigate();
  const addNewRoute = useAddNewRoute();

  const form = useForm({
    initialValues: {
      routeID: "",
      name: "",
      description: "",
      source: "",
      destination: "",
    },
  });
  const [viaPoints, setViaPoints] = useState<
    { value: string; label: string }[]
  >([]);

  return (
    <Modal
      opened
      onClose={() => {
        navigate(-1);
      }}
      title="New Route"
    >
      <LoadingOverlay visible={addNewRoute.loading} overlayBlur={2} />

      <form
        onSubmit={form.onSubmit(async (values) => {
          const routes: Location[] = [];
          routes.push({ city: values.source });
          viaPoints.forEach((viaPoint) => {
            routes.push({ city: viaPoint.label });
          });
          routes.push({ city: values.destination });
          await addNewRoute.fn({
            routeID: values.routeID,
            name: values.name,
            description: values.description,
            source: {
              city: values.source,
            },
            destination: {
              city: values.destination,
            },
            route: routes,
            driver: [],
            status: TRouteStatus.NotYetDispatched,
            locationStatus: LocationStatus.NotYetDispatched,
            capacity: 0,
            packages: [],
          });
          navigate(-1);
        })}
        className="space-y-2"
      >
        <TextInput
          required
          label="Route ID"
          placeholder="eg. RT-4551683"
          description="This is the ID of the tracking, it will be used to identify the tracking in the system."
          rightSection={
            <ActionIcon
              variant="outline"
              onClick={() => {
                // generate random id based on current time
                const date = new Date(new Date().getTime());
                // convert numbers to alphabets
                const id = `RT-${date.getFullYear()}-${
                  date.getMonth() + 1
                }-${date.getDate()}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}`;

                form.setFieldValue("routeID", id);
              }}
            >
              <GiPerspectiveDiceSixFacesRandom />
            </ActionIcon>
          }
          {...form.getInputProps("routeID")}
        />

        <TextInput
          label="Name"
          placeholder="eg. Daily Morning Route"
          {...form.getInputProps("name")}
        />
        <TextInput
          label="Description"
          placeholder="eg. Speacial Packages"
          {...form.getInputProps("description")}
        />
        <div className="flex flex-col p-2 space-y-2 border border-text2 border-solid rounded-md">
          <div className="flex space-x-2">
            <TextInput
              label="Source"
              required
              placeholder="eg. Paonta Sahib"
              className="w-full"
              {...form.getInputProps("source")}
            />
            <TextInput
              label="Destination"
              required
              className="w-full"
              placeholder="eg. Delhi"
              {...form.getInputProps("destination")}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <MultiSelect
              label="Via Stops"
              data={viaPoints}
              placeholder="Select items"
              searchable
              creatable
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(query) => {
                const item = { value: query, label: query };
                setViaPoints((current) => [...current, item]);
                return item;
              }}
            />
          </div>
        </div>

        <Group position="right" mt="md">
          <Button type="submit">Create Route</Button>
        </Group>
      </form>
    </Modal>
  );
};
