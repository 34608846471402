import { BrowserRouter } from "react-router-dom";
import { AppRouter } from "./App.router";
import { ThemeProvider } from "./utils/theme/theme";

function App() {
  return (
    <div className="h-full">
      <BrowserRouter>
        <ThemeProvider>
          <AppRouter />
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
