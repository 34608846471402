import {
  ActionIcon,
  Button,
  Group,
  LoadingOverlay,
  Modal,
  NativeSelect,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  LocationStatus,
  TRouteStatus,
} from "../../admin/troutes/_data/model/troute";
import { useAllTruckRoutes } from "../../admin/troutes/_data/troutes_hook";
import { Shipment } from "../../user/_data/shipment_model";
import { useAddNewPackage } from "../_data/package_hook";
import getID from "./getID";

export const NewTrackModal = () => {
  const navigate = useNavigate();
  const routes = useAllTruckRoutes();
  const addNewPackage = useAddNewPackage();

  // Already Params
  const params = useLocation().state as any;
  const shipment = params?.shipment as Shipment | undefined;

  const form = useForm({
    initialValues: {
      id: "",
      source: shipment?.fromCity ?? "",
      destination: shipment?.toCity ?? "",
      serviceType: shipment?.itemCategory ?? "",
      packageType: "",
      packageWeight: "",
      image: "",
      ownerName: shipment?.senderName ?? "",
      ownerPhone: shipment?.phone ?? "",
      ownerAddress:
        shipment != null
          ? `${shipment.fromCity}, ${shipment.fromState}, ${shipment.fromCountry}`
          : "",
      recipientName: shipment?.recieverName ?? "",
      recipientPhone: "",
      recipientAddress:
        shipment != null
          ? `${shipment.toCity}, ${shipment.toState}, ${shipment.toCountry}`
          : "",
      routeID: "",
    },

    validate: {
      //   email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  return (
    <Modal
      opened
      onClose={() => {
        navigate(-1);
      }}
      title="New Package"
    >
      {routes.isLoading ? (
        <div>Loading...</div>
      ) : (
        <form
          onSubmit={form.onSubmit(async (values) => {
            await addNewPackage.fn({
              id: values.id,
              source: {
                city: values.source,
              },
              destination: {
                city: values.destination,
              },
              serviceType: values.serviceType,
              packageType: values.packageType,
              packageWeight: values.packageWeight,
              image: values.image,
              routeID: values.routeID,
              createdAt: new Date(),
              locations: [],
              status: LocationStatus.NotYetDispatched,
              owner: {
                name: values.ownerName,
                phone: values.ownerPhone,
                address: values.ownerAddress,
              },
              recipient: {
                name: values.recipientName,
                phone: values.recipientPhone,
                address: values.recipientAddress,
              },
            });
            navigate(-1);
          })}
          className="space-y-2"
        >
          <LoadingOverlay visible={addNewPackage.loading} />
          <TextInput
            required
            label="Tracking ID"
            placeholder="eg. XR-4551683"
            description="This is the ID of the tracking, it will be used to identify the tracking in the system."
            rightSection={
              <ActionIcon
                variant="outline"
                onClick={() => {
                  const date = new Date();
                  const correctedId = "PD-" + getID(date);
                  console.log(correctedId);
                  form.setFieldValue("id", correctedId);
                }}
              >
                <GiPerspectiveDiceSixFacesRandom />
              </ActionIcon>
            }
            {...form.getInputProps("id")}
          />

          <div className="flex flex-col p-2 space-y-2 border border-text2 border-solid rounded-md">
            <div className="flex space-x-2">
              <div className="flex flex-col space-y-2 w-full">
                <div>Owner Details</div>
                <TextInput
                  label="Owner Name"
                  required
                  placeholder="eg. Rohit"
                  className="w-full"
                  {...form.getInputProps("ownerName")}
                />
                <TextInput
                  label="Phone No."
                  required
                  placeholder="eg. 8247285575"
                  className="w-full"
                  {...form.getInputProps("ownerPhone")}
                />
                <TextInput
                  label="Address"
                  required
                  placeholder="eg. Address"
                  className="w-full"
                  {...form.getInputProps("ownerAddress")}
                />
              </div>
              <div className="flex flex-col space-y-2 w-full">
                <div>Recipient Details</div>
                <TextInput
                  label="Rec Name"
                  required
                  placeholder="eg. Rohit"
                  className="w-full"
                  {...form.getInputProps("recipientName")}
                />
                <TextInput
                  label="Phone No."
                  required
                  placeholder="eg. 8247285575"
                  className="w-full"
                  {...form.getInputProps("recipientPhone")}
                />
                <TextInput
                  label="Address"
                  required
                  placeholder="eg. Address"
                  className="w-full"
                  {...form.getInputProps("recipientAddress")}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col p-2 space-y-2 border border-text2 border-solid rounded-md">
            <div className="flex space-x-2">
              <TextInput
                label="Source"
                required
                placeholder="eg. Paonta Sahib"
                className="w-full"
                {...form.getInputProps("source")}
              />
              <TextInput
                label="Destination"
                required
                className="w-full"
                placeholder="eg. Delhi"
                {...form.getInputProps("destination")}
              />
            </div>
          </div>

          <NativeSelect
            data={routes
              .data!.filter((e) => e.status === TRouteStatus.NotYetDispatched)
              .map((r) => ({
                value: r.id!,
                label: `${r.name ?? ""} (${r.routeID})`,
              }))}
            label="Route"
            placeholder="Pick a route"
            clearButtonLabel="Clear selection"
            clearable
            {...form.getInputProps("routeID")}
            onChange={(e) => form.setFieldValue("routeID", e.target.value)}
          />

          <Group position="right" mt="md">
            <Button type="submit">Submit</Button>
          </Group>
        </form>
      )}
    </Modal>
  );
};
