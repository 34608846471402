import { Button, Divider } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { TRoutesList } from "./components/troutes_list";

export const TruckRoutes = () => {
  const navigate = useNavigate();

  return (
    <div className="h-full flex flex-col w-full">
      <div className="text-xl p-4 flex items-center justify-between">
        <div>Routes</div>
        <div className="ml-6 ">
          <Button
            onClick={() => {
              navigate("new");
            }}
          >
            Add New Route
          </Button>
        </div>
      </div>

      <Divider />

      <div>
        <TRoutesList />
      </div>
    </div>
  );
};
