import { Button } from "@mantine/core";
import { getAuth, signOut } from "firebase/auth";
import React from "react";
import { BiLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { ThemeSwither } from "../../../components/ThemeSwither";

function Profile() {
  function signOutUser() {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Signed Out");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center items-center w-full h-full">
      <div className="flex flex-col">
        <ThemeSwither />
        <Button
          rightIcon={<BiLogOut size={25} className="text-error" />}
          className="px-12 mt-2"
          onClick={signOutUser}
        >
          Log Out
        </Button>
        <Button
          className="px-12 mt-2"
          onClick={() => {
            navigate("/track");
          }}
        >
          Exit
        </Button>
      </div>
    </div>
  );
}

export default Profile;
