import { Box, Center, SegmentedControl } from "@mantine/core";
import { BsFillSunFill } from "react-icons/bs";
import { MdNightsStay } from "react-icons/md";
import { useTheme } from "../utils/theme/theme";

export const ThemeSwither = () => {
  const theme = useTheme();
  return (
    <div>
      <SegmentedControl
        className="w-full"
        value={theme.themeMode}
        onChange={(e) => {
          theme.setThemeMode(e as "light" | "dark");
        }}
        radius={8}
        data={[
          {
            label: (
              <Center>
                <BsFillSunFill size={16} />
                <Box ml={4}>Light</Box>
              </Center>
            ),
            value: "light",
          },
          {
            label: (
              <Center>
                <MdNightsStay size={16} />
                <Box ml={4}>Dark</Box>
              </Center>
            ),
            value: "dark",
          },
        ]}
      />
    </div>
  );
};
