import { Button, Divider } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  useDeleteShipment,
  useGetAllShipment,
} from "../../user/_data/user_hook";

export const ShipmentsHome = () => {
  const shipments = useGetAllShipment();
  const delShipment = useDeleteShipment();
  const navigate = useNavigate();

  return (
    <div className="h-full flex flex-col w-full">
      <div className="text-xl p-4 flex items-center justify-between">
        <div>Shipments </div>
        <div className="ml-6 ">
          {/* <Button
        onClick={() => {
        //   navigate("new");
        }}
      >
        Add New Route
      </Button> */}
        </div>
      </div>

      <Divider />

      {shipments.loading || delShipment.loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {/*  */}
          <div className="grid grid-cols-2 sm:grid-cols-[repeat(auto-fill,_300px)]   gap-2 pt-6 w-full px-4 ">
            {shipments.data!.map((s) => {
              return (
                <div className="border border-solid border-backgroundShade p-2 flex flex-col rounded-xl">
                  {/*  */}
                  <div className="flex space-x-2">
                    <div className="flex flex-col text-sm w-full">
                      <div className="font-bold underline">From</div>
                      <div>{s.fromCity} , </div>
                      <div>{s.fromState} , </div>
                      <div>{s.fromCountry}</div>
                    </div>
                    <div
                      className="flex flex-col text-sm w-full border-0 border-l-2 pl-2
                     border-dashed border-onbackgroundshade"
                    >
                      <div className="font-bold underline">To</div>
                      <div>{s.toCity} , </div>
                      <div>{s.toState} , </div>
                      <div>{s.toCountry}</div>
                    </div>
                  </div>
                  {/*  */}

                  <div className="text-sm">
                    <div> Category : {s.itemCategory}</div>
                    <div> Representing : {s.representing}</div>
                    <div> Shipping Freq : {s.shippingFrequency}</div>
                  </div>

                  <div className="pt-1">Phone : {s.phone}</div>
                  <div className="pt-2 flex space-x-2">
                    <a className="w-full" href={`tel:${s.phone}`}>
                      <Button className="w-full">Call</Button>
                    </a>

                    <Button
                      onClick={() => {
                        navigate(`/admin/tracking/new`, {
                          state: {
                            shipment: s,
                          },
                        });
                      }}
                      className="w-full"
                      variant="outline"
                    >
                      Make Package
                    </Button>
                  </div>
                  <Button
                    className="w-full mt-2"
                    color={"red"}
                    onClick={() => {
                      delShipment.fn(s.id!);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              );
            })}
          </div>

          {/*  */}
        </div>
      )}
    </div>
  );
};
