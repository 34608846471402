import React from "react";
import { Package } from "../_data/_models/package";
import { PackageLocationStatus } from "./tracking-status";

export const TrackingCard = (props: {
  package: Package;
  onClick: () => void;
}) => {
  return (
    <div
      className={`px-3 py-3 flex flex-col rounded-md bg-backgroundshade
            sm:min-w-[250px] sm:max-w-[250px] select-none cursor-pointer
            transition-all
            hover:bg-text2/30
             outline-[2px] outline-primary
    `}
      onClick={props.onClick}
    >
      {/* ID And Status */}
      <div className="flex flex-col">
        <PackageLocationStatus status={props.package.status} />
        <span>{props.package.id}</span>
        <span className="text-sm">By : {props.package.owner.name}</span>
        <span className="text-sm">To : {props.package.recipient.name}</span>
      </div>

      {/*  */}

      {/* Last Update */}

      <div className="h-6"></div>

      <div className="border-[2px] border-onbackgroundshade  border-solid rounded-md  flex ">
        {/* Left */}

        <div className="flex flex-col px-2 py-2 space-y-1 text-xs text-onbackgroundshade">
          <div className="text-onbackground">
            Last Location :{" "}
            {props.package.locations.length > 0
              ? props.package.locations[props.package.locations.length - 1].city
              : ""}
          </div>
        </div>

        {/*  */}
      </div>
    </div>
  );
};
