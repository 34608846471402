import { getApps, initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBO0lZQUkL4j4AiEF0NR322yJ2EQaTt7d8",
  authDomain: "kingsuperfast-f4e79.firebaseapp.com",
  projectId: "kingsuperfast-f4e79",
  storageBucket: "kingsuperfast-f4e79.appspot.com",
  messagingSenderId: "664663469849",
  appId: "1:664663469849:web:89d81c5a1c4fc135a81f88",
  measurementId: "G-3F59YDGEZF",
};

export function initFirebase() {
  if (getApps().length === 0) {
    initializeApp(firebaseConfig);
  }
}
