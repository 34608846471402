import { FcGoogle } from "react-icons/fc";
import { Button } from "@mantine/core";
import React from "react";
import InputField from "../../components/inputfield";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const [haveAccount, setHaveAccount] = React.useState(false);
  const [forgetPassword, setForgetPassword] = React.useState(false);
  const lineClass = `${
    forgetPassword ? "w-24 transition-all duration-50 ease-in-out" : "w-14"
  } bg-black h-[1px] `;
  const navigate = useNavigate();
  return (
    <div
      className={
        "py-10 w-full h-fit  bg-[url('https://img.freepik.com/free-vector/delivery-service-with-masks-concept_23-2148498421.jpg?w=740&t=st=1659261990~exp=1659262590~hmac=26a6fa23b1c1d81a1664f11f22b78dd0da002ea76e4627d543921dff826e166c')]"
      }
    >
      <div
        className="flex flex-col items-center text-black/80 max-w-md p-2 bg-gradient-to-b from-white/50 
        via-white/80 to-white mx-auto rounded-xl backdrop-blur-lg shadow-lg "
      >
        <div
          style={{
            textShadow: "5px 5px 5px background",
          }}
          className="mt-4 drop-shadow-xl shadow-background font-bold text-3xl text-center sm:text-4xl xl:text-5xl"
        >
          <div>Welcome</div>
          <div className="text-lg mt-2">Demo Super Fast</div>
          <div className="text-sm font-medium mt-2">
            Delivery Services at your doorsteps
          </div>
        </div>
        <div className="flex flex-col items-center w-full  rounded-t-none md:rounded-t-2xl pt-8">
          <Button
            className=" rounded-full bg-transparent border border-solid text-black/80 border-black/80 
            max-w-xs w-full mx-1 mb-4 hover:bg-blue-200"
          >
            <span>Login With Google</span>
            <FcGoogle size={20} className="hover:animate-pulse ml-1" />
          </Button>

          <div className="flex justify-between items-center w-[300px] h-auto transition-all duration-50 ease-in-out">
            <div className={lineClass} />
            <div className="text-center  text-xs">
              {forgetPassword ? (
                "Reset Password"
              ) : (
                <span>
                  {" "}
                  or <span>{haveAccount ? "sign in" : "sign up"}</span> with
                  phone or email
                </span>
              )}
            </div>
            <div className={lineClass} />
          </div>
          <div className="flex flex-col items-center space-y-3 mt-5 w-[300px]">
            <InputField
              type="email"
              placeholder="Email or phone"
              textAlign="center"
            />

            <InputField
              type="password"
              placeholder="Password"
              textAlign="center"
              className={` ${forgetPassword ? "hidden" : ""}`}
            />

            <div className="w-full">
              <Button className="rounded w-full mt-4 mb-2">
                {forgetPassword
                  ? "Reset Password"
                  : haveAccount
                  ? "Sign In"
                  : "Sign Up"}
              </Button>
              <Button
                className="rounded w-full mt-2 mb-2"
                onClick={() => {
                  navigate("/admin/login");
                }}
              >
                Admin Log In
              </Button>
            </div>
            <div className="text-xs text-center">
              {haveAccount ? "Don't" : "Already"} have an account?{" "}
              <span
                className="text-primary cursor-pointer"
                onClick={() => {
                  setHaveAccount(!haveAccount);
                  setForgetPassword(false);
                }}
              >
                {haveAccount ? "Sign Up" : "Sign In"}
              </span>
              <div
                className="mt-1 text-primary cursor-pointer"
                onClick={() => {
                  setForgetPassword(!forgetPassword);
                }}
              >
                {forgetPassword ? "" : "Forgot Password?"}
              </div>
            </div>

            <div className="flex flex-col sm:flex-row"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
