export function enumKey(enumV: any, val: any) {
  return pascalToProperCase(
    Object.keys(enumV).find((k) => (enumV as any)[k] === val) ?? ""
  );
}

export function pascalToProperCase(val: string): string {
  return val
    .split("")
    .reduce((acc, c) => {
      const lastAcc = acc[acc.length - 1];
      // if lastAcc is lowercase  and c is uppercase, add a space
      if (
        lastAcc.charCodeAt(0) >= 97 &&
        c.charCodeAt(0) >= 65 &&
        c.charCodeAt(0) <= 90
      ) {
        acc += " ";
      }
      return acc + c;
    }, " ")
    .trim();
}
